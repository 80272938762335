<template>
  <docs-layout>
    <div class="space-y-4 w-full text-left mb-4">
      <h1 class="text-3xl font-bold">Client Table </h1>
      <h3 class="text-xl font-bold">Basic</h3>
      <asom-client-table
        :columns="defaultTable.columns"
        :data="defaultTable.data"
        :filterable="false"
        :pagination="false"
      />
      <h3 class="text-xl font-bold">With footer</h3>
      <asom-client-table
        :columns="defaultTable.columns"
        :data="defaultTable.data"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:tfoot>
          <tfoot>
            <tr>
              <td>
              Sample footer content
              </td>
            </tr>
          </tfoot>
        </template>
      </asom-client-table>
      <h3 class="text-xl font-bold">With filter, pagination, sorting and custom column rendering</h3>
      <p class="italic text-sm">The first and last columns are set to be unsortable</p>
      <asom-client-table
        :columns="withAll.columns"
        :data="withAll.data"
        :sortableColumns="sortableColumns1"
      >
        <template v-slot:header_column_1="slotScoped">
          <span class="text-red-300">{{slotScoped.column}}</span>
        </template>
        <template v-slot:column_1="slotScoped">
          <span class="text-red-300">{{slotScoped.data}}</span>
        </template>
        <template v-slot:no="slotScoped">
          {{slotScoped.rowIndex + 1}}
        </template>
        <template v-slot:action>
          <asom-button text="Action" />
        </template>
      </asom-client-table>

      <h3 class="text-xl font-bold">Table with radio (single select)</h3>
      <p>Recommendation: use without pagination and filtering</p>
      <p>Required a watcher on data to clear input on data changed to avoid unwanted selected option</p>
      <p>Row selected: {{singleSelect}}</p>
      <asom-client-table
        :columns="tableWithRadio.columns"
        :data="tableWithRadio.data"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_select><span></span></template>
        <template v-slot:select="scopedSlots">
          <input
            type="radio"
            :value="scopedSlots.rowIndex"
            v-model="singleSelect"
          />
        </template>
      </asom-client-table>
      <h3 class="text-xl font-bold">Table with checkbox (multiselect)</h3>
      <p>Recommendation: use without pagination and filtering</p>
      <p>Required a watcher on data to clear input on data changed to avoid unwanted selected option</p>
      <p>Row selected: {{multiSelect}}</p>
      <asom-client-table
        :columns="tableWithCheckbox.columns"
        :data="tableWithCheckbox.data"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_select>
          <input
            type="checkbox"
            v-model="multiSelectAll"
            @change="onSelect"
          />
        </template>
        <template v-slot:select="scopedSlots">
          <input
            class="mx-2"
            type="checkbox"
            v-model="scopedSlots.rowData.select"
            @change="onSelect"
          />
        </template>
      </asom-client-table>
      
      <h3 class="text-xl font-bold">Table with custom classes for headers and cells (NEW)</h3>
      <p>We can apply custom classes for headers and cells. Please read more at file tableMixins.js</p>
      <asom-client-table
        :columns="defaultTable.columns"
        :data="defaultTable.data"
        tableClasses="table_XYZ"
        :thClasses="tableWithCustomClasses.thClasses"
        :tdClasses="tableWithCustomClasses.tdClasses"
      />
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

const SAMPLE_TEXT = "Rem temporibus necessitatibus sed. Qui est qui quis suscipit modi eum quod. Nesciunt qui soluta dolores est minima aspernatur aliquid. Et dolor soluta ea et dignissimos repellat.";

const generateColumns = (noOfCols = 4) => {
  let cols = []
  for (let i = 0; i<noOfCols; i++) {
    cols.push('column_' + (i + 1))
  }
  return cols
}

const generateData = ({
  columns = [],
  noOfRows = 5,
  sampleText = SAMPLE_TEXT,
  maxWordsPerCell = 4
}) => {
  let data = []
  const words = sampleText.split(/[ ,]+/)
  const wordsLen = words.length

  for (let i=1; i<=noOfRows; i++) {
    let row = {}

    columns.forEach((col) => {
      const _index = Math.floor(Math.random() * wordsLen)
      let _len = Math.floor(Math.random() * maxWordsPerCell + 1)
      _len = (_index + _len <= wordsLen) ? _len : 1
      row[col] = words.slice(_index, _index + _len).join(' ')
    })

    data.push(row)
  }

  return data
}

const generateTableData = (options = {}) => {
  const {
    noOfCols = 4,
    noOfRows = 5,
    sampleText = SAMPLE_TEXT,
    maxWordsPerCell = 4,
  } = options;

  const columns = generateColumns(noOfCols)
  const data = generateData({
    columns,
    noOfRows,
    sampleText,
    maxWordsPerCell
  })
  
  return { columns, data }
}

export default {
  components: {
    DocsLayout,
  },
  data() {
    const defaultTable = generateTableData()
    const fullFeatureClientTable = generateTableData({
      noOfRows: 245,
    })
    return {
      defaultTable: defaultTable,
      withAll: {
        columns: [ 'no', ...fullFeatureClientTable.columns, 'action' ],
        data: fullFeatureClientTable.data
      },
      tableWithRadio: {
        columns: [ 'select', ...defaultTable.columns, ],
        data: defaultTable.data.map((r) => ({
          select: false,
          ...r,
        }))
      },
      tableWithCheckbox: {
        columns: [ 'select', ...defaultTable.columns, ],
        data: defaultTable.data.map((r) => ({
          select: false,
          ...r,
        }))
      },
      singleSelect: null,
      multiSelect: [],
      tableWithCustomClasses: {
        thClasses: {
          'column_1': 'bg-red-100 font-normal',
          'column_2': [
            'bg-blue-100',
            'font-normal',
          ],
        },
        tdClasses: {
          'column_1': {
            'font-bold': () => true,
            'bg-red-200': (rowData, rowIndex) => rowIndex%2==0,
            'bg-red-100': (rowData, rowIndex) => rowIndex%2==1,
          },
          'column_2': {
            'bg-blue-100': (rowData) => rowData.column_2.length > 10,
          }
        }
      }
    }
  },
  computed: {
    sortableColumns1() {
      return this.withAll.columns.slice(1, 5)
    },
    multiSelectAll: {
      get() {
        return this.tableWithCheckbox.data.every(r => r.select)
      },
      set(newValue) {
        this.tableWithCheckbox.data = this.tableWithCheckbox.data.map(r => ({
          ...r,
          select: newValue,
        }))
      }
    }
  },
  methods: {
    onSelect() {
      this.$nextTick(() => {
        const newValue = []
        this.tableWithCheckbox.data.forEach((r, i) => {
          if (r.select)
            newValue.push(i)
        })
        this.multiSelect = newValue
      })
    }
  }
}
</script>

<style>

</style>